.home-hero {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: -91px;
    .wires {
        position: relative;
        top: 140px;
        width: 87.3333333333vw;
        @media (max-width: 991px) {
            top: 80px;
        }
        @media (max-width: 575px) {
            display: none;
        }
    }
    &__text {
        position: relative;
        bottom: -80px;
        @media (max-height: 800px) and (min-width: 922px) {
            bottom: 0;
        }
        @media (max-height: 600px) and (min-width: 992px) {
            bottom: 100px;
        }
        p {
            color: var(--white);
        }
    }
    .twenty-four-hour-badge {
        width: 108px;
        position: absolute;
        bottom: 50px;
        right: 50px;
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.partners-section {
    padding: 120px 0;
    @media (max-width: 991px) {
        padding: 60px 0;
    }
    &__icons {
        img {
            height: 130px;
        }
        h5 {
            margin-top: 40px;
            text-align: center;
            @media (max-width: 991px) {
                margin-bottom: 60px;
            }
        }
        div:nth-of-type(3) {
            h5 {
                @media (min-width: 992px) {
                    padding-left: 30px;
                }
            }
        }
    }
    &__bottom {
        padding-top: 120px;
        @media (max-width: 991px) {
            padding-top: 10px;
        }
        h2 {
            margin-top: 30px;
        }
    }
    &__logos {
        padding-top: 90px;
        display: flex;
        align-items: flex-end;
        @media (max-width: 991px) {
            padding-top: 60px;
        }
        img {
            display: inline-block;
        }
        figure {
            display: inline-block;
            &:first-of-type {
                width: 92px;
                @media (max-width: 991px) {
                    width: 76px;
                }
                @media (max-width: 325px) {
                    margin-right: 20px;
                }
            }
            &:last-of-type {
                width: 260px;
                position: relative;
                right: -60px;
                @media (max-width: 991px) {
                    width: 195px;
                    right: -30px;
                }
                @media (max-width: 325px) {
                    position: static;
                }
            }
        }
    }
}
