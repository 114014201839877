.contact-form-section {
    padding: 120px 0 80px;

    @media (max-width: 991px) {
        padding: 60px 0;
    }
}

.contact-details {
    padding-bottom: 120px;

    @media (max-width: 991px) {
        padding-bottom: 60px;
    }

    address {
        margin-bottom: 30px;

        p:first-of-type {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
            font-style: normal;
        }
    }

    &__details {
        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }

    &__links {
        margin-bottom: 30px;

        p {
            margin-bottom: 0;

            &:last-of-type {
                color: var(--primary);
                @apply mt-4;

                i {
                    @apply mr-4;
                }
            }
        }
    }

    &__support {
        p {
            &:nth-of-type(2) {
                margin-bottom: 0;
            }
        }
    }
}

.emergency-contacts {
    @apply gap-y-12;

    h3 {
        color: var(--text-dark);
        margin-bottom: 0;
    }

    h4 {
        color: var(--primary);
        text-transform: uppercase;
        font-family: "Proxima Nova", "Arial", sans-serif;
        font-weight: 700;
        font-size: small;
        margin: auto 0 0;
    }

    p {
        color: var(--primary)
    }
}
