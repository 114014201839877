// partners page

.partners-content-top {
    padding: 120px 0;

    @media (max-width: 991px) {
        padding: 60px 0;
    }

    h4 {
        margin-bottom: 120px;

        @media (max-width: 991px) {
            margin-bottom: 60px;
        }
    }
}

.partner {
    padding: 60px 0 80px;
    margin-bottom: 30px;
    background: var(--light-bg);
    display: block;

    h2 {
        font-size: 6rem;
        line-height: 6rem;
        transition: all 0.3s;
    }

    &__main-img {
        display: block;
        margin-bottom: 20px;
    }

    &__stats {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 30px;

        @media (max-width: 400px) {
            grid-template-columns: repeat(1, 1fr);
        }

        p {
            @media (max-width: 400px) {
                text-align: center;
            }

            margin-bottom: 0;

            &:first-of-type {
                font-size: 5rem;
                line-height: 5rem;
                transition: all 0.3s;
            }

            &:last-of-type {
                font-size: 3rem;
                line-height: 4rem;
            }
        }
    }

    &__logo {
        @media (max-width: 767px) {
            display: none;
        }

        img {
            height: 40px;
            position: relative;
            top: 20px;
        }
    }
}

.partners-content-bottom {
    padding: 90px 0 120px;

    @media (max-width: 991px) {
        padding: 30px 0 60px;
    }
}

.global-presence {
    padding-bottom: 120px;

    @media (max-width: 991px) {
        padding-bottom: 60px;
    }

    p:last-of-type {
        margin-bottom: 30px;
    }

    img {
        width: 100%;
    }
}

// partner page

.partner-content-top {
    padding: 120px 0;

    @media (max-width: 991px) {
        padding: 60px 0;
    }

    img {
        display: block;
        margin-top: 120px;
        width: 100%;

        @media (max-width: 991px) {
            margin-top: 60px;
        }
    }

    &__logo {
        padding: 0 30px;

        @media (max-width: 767px) {
            display: none;
        }

        img {
            width: 100%;
            margin-top: 0;
        }
    }
}

.key-points-banner {
    background: var(--light-bg);
    padding: 60px 0 0;

    .key-point {
        padding-bottom: 60px;
    }
}

.partner-content-bottom {
    padding: 120px 0;

    @media (max-width: 991px) {
        padding: 60px 0;
    }
}