.site-footer {
    @apply pt-12;

    background: var(--dark);

    &__top-content {
        @apply flex flex-col items-start;

        @screen lg {
            @apply flex-row items-end justify-between;
            @apply pt-12;
        }

        .jb-logo {
            img, address {
                @apply mb-12;
            }

            address p {
                @apply mb-0;
            }

            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
        }

        .contact-details__links {
            @apply mb-0;
        }

        .accreditation-logos {
            @apply flex items-center justify-center gap-12;

            img {
                @apply max-h-24;
            }
        }
    }

    &__bottom-content {
        @apply flex flex-col items-start justify-between py-12;

        @screen lg {
            @apply flex-row;
        }

        a {
            transition: all 0.3s;
            display: inline-block;
            margin-left: 5px;

            &:hover, &:focus, &:active {
                color: var(--white);
            }
        }

        span {
            display: inline-block;
            margin-right: 5px;
        }

        img {
            margin-left: 10px;
        }

        div:last-of-type {
            @media (max-width: 991px) {
                margin-top: 30px;
            }
        }

        p {
            @media (max-width: 991px) {
                text-align: center;
            }
        }
    }

    &__address br {
        @apply hidden;
    }
}