.error-page {
    height: 100vh;
    position: relative;
    .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
    }
    h3 {
        margin-bottom: 50px;
        color: var(--text-grey);      
    }
    .wires {
        position: absolute;
        bottom: 100px;
        left: 0;
        width: 85%;
        pointer-events: none;
        @media (max-width: 991px) {
            display: none;
        }
    }
}