.page-header {
    @apply relative -mt-40;
    // margin-top: -100px;
    // position: relative;

    h1 {
        @apply relative;
        @apply capitalize;

        padding: 275px 0 80px;
        z-index: 2;
    }

    .breadcrumb {
        @apply font-sans font-bold text-text-grey;
        @apply uppercase;
        @apply -mt-4;

        font-size: 1.2rem;
        line-height: 2.4rem;
    }

}

.page-header,
.home-hero {
    @apply relative;

    &::before {
        content: '';

        @apply w-full fixed left-0 z-0;

        height: calc(100% - 91px);
        background-color: rgba(9, 11, 28, 0.9);
        top: 91px;

        @media (max-width: 991px) {
            top: 104px;
            height: calc(100% - 72px);
        }
    }
}

.main-header {
    @apply z-20;

    ~ .site-header {
        top: 5.5rem;
    }
}
