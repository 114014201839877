.about-content-top {
    padding: 120px 0;

    @media (max-width: 991px) {
        padding: 60px 0;
    }

    img {
        display: block;

        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }
}

.divider {
    border-bottom: 1px solid #bcbcbc;
    position: relative;

    &::after {
        content: '';
        background: #bcbcbc;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: -3px;
    }
}

.about-content-mid {
    padding: 120px 0 0;
    margin-top: -2px;
    z-index: 0;

    @media (max-width: 991px) {
        padding: 60px 0 0;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        *:last-child {
            @apply mb-0;
        }
    }

    img {
        display: block;

        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }
}

.about-content-bottom {
    padding: 120px 0;

    @media (max-width: 991px) {
        padding: 60px 0;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            color: var(--dark);
        }
    }

    img {
        display: block;

        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }
}

.expertise {
    padding: 120px 0;
    margin-top: -2px;
    z-index: 0;

    h2 {
        @apply mb-0;

        + h3 {
            @apply mt-4;
        }
    }

    h3 {
        @apply text-text-grey;
    }

    @media (max-width: 991px) {
        padding: 60px 0;
    }
}
