@mixin discoverMoreBtnStyles() {
    text-transform: uppercase;
    @apply font-sans font-bold;
    color: var(--white);
    display: block;
    margin: auto 0 0;

    &::after {
        content: '\f178';
        font-family: 'Font Awesome 6 Pro';
        float: right;
        position: relative;
        left: 0;
        transition: all 0.3s;
    }
}

.zoom-effect-container {
    @media (min-width: 1200px) {
        float: left;
        position: relative;
        width: 370px;
        height: 210px;
        margin: 0 auto;
        overflow: hidden;
    }

    &--page-link {
        margin-bottom: 50px;

        @media (min-width: 1200px) {
            width: calc(100% - 30px);
            height: 307px;
        }

        @media (max-width: 340px) {
            margin-bottom: 0;
        }
    }

    &--thumbnail {
        @media (min-width: 1200px) {
            width: 570px;
            height: 324px;
        }
    }

    &--partner {
        @media (min-width: 1200px) {
            width: 568px;
            height: 370px;
        }
    }
}

.card-image {
    @media (min-width: 1200px) {
        position: absolute;
        top: 0;
        left: 0;

        img,
        figure {
            transition: 0.4s ease;
        }
    }
}

.card {
    display: block;
    margin-bottom: 120px;

    @media (max-width: 991px) {
        margin-bottom: 30px;
    }

    &__text {
        padding: 30px;
        height: 100%;

        @media (min-width: 1200px) {
            height: calc(100% - 210px);
        }

        h3,
        p,
        span {
            transition: all 0.3s;
        }

        p {
            color: var(--white);

            &:last-of-type {
                margin-bottom: 40px;
            }
        }

        span {
            @include discoverMoreBtnStyles()
        }
    }

    &:hover,
    &:active,
    &:focus {
        .card__text span::after {
            left: 10px;
        }

        @media(min-width: 1200px) {

            .zoom-effect-container>.card-image>img,
            .zoom-effect-container>.card-image>figure {
                -webkit-transform: scale(1.08);
                transform: scale(1.08);
            }
        }
    }

    &--primary-bg {
        background: var(--primary);
    }

    &--secondary-bg {
        background: var(--secondary);
    }

    &--tertiary-bg {
        background: var(--tertiary);
    }
}