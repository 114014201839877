.site-header {
    @apply sticky;
    @apply top-0;
    @apply z-10;
    background: rgba(9, 11, 28, 0.9);

    @media (max-width: 991px) {
        position: fixed;
        width: 100vw;
    }

}

.site-navbar {
    @apply flex;
    @apply flex-wrap;
    @apply items-center;
    @apply justify-between;
    padding: 10px 0;

    @media (max-width: 991px) {
        padding-bottom: 10px;
    }

    @screen lg {
        @apply flex-nowrap;
    }

    &__logo {
        width: 132px;

        @media (min-width: 992px) {
            height: 71px;
        }

        @media (max-width: 991px) {
            width: 140px;
            bottom: 0;
            margin: 10px 0;
            // position: relative;
            // z-index: 10;
        }
    }

    .hamburger {
        @media (max-width: 991px) {
            position: relative;
            z-index: 10;
        }
    }

    &__list {
        @apply hidden;
        @apply w-full;

        @media (max-width: 991px) {
            width: 100% !important;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow-y: scroll;
            background: var(--dark);
            z-index: 0;
            padding-top: 100px;
        }

        @media (max-width: 991px) {
            flex-direction: column;
        }

        @media (min-width: 992px) {
            @apply w-auto;
            @apply block;
        }

        ul {
            @apply flex;
            @apply mb-0;
            @apply list-none;
            
            @media (max-width: 991px) {
                @apply mx-auto;
                
                width: 100%;
                flex-direction: column;
                // max-width: 768px;
                padding-right: 15px;
            }
            
            @screen sm {
                max-width: 575px;
            }
            
            @screen md {
                max-width: 768px;
            }

            @screen lg {
                @apply max-w-none;
            }

            li {
                @apply relative;
                @apply font-sans;
                font-weight: bold;
                font-size: 1.4rem;
                line-height: 2.4rem;
                color: var(--white);
                text-transform: uppercase;
                margin-left: 40px;
                transition: all 0.3s;

                @media (max-width: 1199px) {
                    margin-left: 25px;
                }

                @media (max-width: 991px) {
                    margin-left: 0;
                    border-bottom: 1px solid rgb(51, 51, 51);

                    &:last-of-type {
                        border-bottom: none;
                    }
                }

                a {
                    height: 69px;
                    display: flex;
                    align-items: center;

                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--primary);
                    }

                    @media (max-width: 991px) {
                        height: initial;
                        padding: 20px 0;

                    }
                }

                .active {
                    color: var(--primary);
                }

                ul {

                    // submenu
                    @media (min-width: 992px) {
                        opacity: 0;
                    }

                    pointer-events: none;
                    background: var(--primary);
                    transition: all 0.2s;
                    position: absolute;
                    top: 60px;
                    display: flex;
                    flex-direction: column;
                    width: 230px;

                    @media (min-width: 992px) {
                        padding: 15px 15px 0;
                    }


                    @media (min-width: 992px) {
                        left: -20px;

                        // triangle
                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 20px;
                            width: 0;
                            height: 0;
                            border: 6px solid transparent;
                            border-bottom-color: var(--primary);
                            border-top: 0;
                            margin-left: -6px;
                            margin-top: -6px;
                        }
                    }

                    @media (max-width: 991px) {
                        position: relative;
                        // display: none;
                        top: 0;
                        background: transparent;
                        width: 100%;
                        pointer-events: initial;
                    }

                    li {
                        margin-left: 0;
                        margin-bottom: 20px;
                        text-transform: capitalize;
                        margin-bottom: 10px;
                        @apply font-sans font-light;

                        @media (max-width: 991px) {
                            border-bottom: none;
                        }

                        a {
                            height: initial;

                            &:hover,
                            &:focus,
                            &:active {
                                @media (min-width: 992px) {
                                    color: var(--black);
                                }
                            }

                            @media (max-width: 991px) {
                                padding: 0;

                            }

                        }
                    }

                }

                &:hover ul {
                    opacity: 1;
                    pointer-events: initial;
                    z-index: 1;
                }


            }
        }
    }
}


.parent-link {
    ul {
        li {
            a.active {
                color: var(--black);

                @media (max-width: 991px) {
                    color: var(--white);
                }

                &:hover,
                &:active,
                &:focus {
                    color: var(--black);

                    @media (max-width: 991px) {
                        color: var(--white);
                    }
                }
            }
        }
    }
}

a.open {
    padding-bottom: 0;
}

.dropdown:hover {
    >.dropdown-menu {
        @apply block;
        @apply top-full;
    }
}

.small-logo {
    width: 100px !important;
}

.mobile-only {
    @media (min-width: 992px) {
        display: none;
    }
}

// when the mobile menu is opened the mobile menu wants to be scrollable but not the body
// this disables scrolling on the body when the mobile menu is open (toggled with js)
// .site-navbar__list has a media query making its position fixed, all positions set to 0 and overflow-y scroll
body.js-mobile-menu-open {
    overflow: hidden;
    height: calc(100vh + 500px);
}
