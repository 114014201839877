/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
 .hamburger {
    @media (min-width: 992px) {
        display: none;
    }
   display: flex;
   cursor: pointer;
   transition-property: opacity, filter;
   transition-duration: 0.15s;
   transition-timing-function: linear;
   font: inherit;
   color: inherit;
   text-transform: none;
   background-color: transparent;
   border: 0;
   margin: 0;
   overflow: visible; }
   .hamburger:hover {
     opacity: 1; }
   .hamburger.is-active:hover {
     opacity: 1; }
   .hamburger.is-active .hamburger-inner,
   .hamburger.is-active .hamburger-inner::before,
   .hamburger.is-active .hamburger-inner::after {
     background-color: white; }
 
 .hamburger-box {
   width: 25px;
   height: 24px;
   display: inline-block;
   position: relative; }
 
 .hamburger-inner {
   display: block;
   top: 50%;
   margin-top: -2px; }
   .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
     width: 25px;
     height: 2px;
     background-color: white;
     position: absolute;
     transition-property: transform;
     transition-duration: 0.15s;
     transition-timing-function: ease; }
   .hamburger-inner::before, .hamburger-inner::after {
     content: "";
     display: block; }
   .hamburger-inner::before {
     top: -8px; }
   .hamburger-inner::after {
     bottom: -8px; }

 /*
    * Vortex
    */
 .hamburger--vortex .hamburger-inner {
   transition-duration: 0.2s;
   transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
   .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
     transition-duration: 0s;
     transition-delay: 0.1s;
     transition-timing-function: linear; }
   .hamburger--vortex .hamburger-inner::before {
     transition-property: top, opacity; }
   .hamburger--vortex .hamburger-inner::after {
     transition-property: bottom, transform; }
 
 .hamburger--vortex.is-active .hamburger-inner {
   transform: rotate(765deg);
   transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
   .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
     transition-delay: 0s; }
   .hamburger--vortex.is-active .hamburger-inner::before {
     top: 0;
     opacity: 0; }
   .hamburger--vortex.is-active .hamburger-inner::after {
     bottom: 0;
     transform: rotate(90deg); }