.accreditations {
    padding-bottom: 120px;
    @media (max-width: 991px) {
        padding-bottom: 0;
    }
    p:last-of-type {
        margin-bottom: 30px;
    }
    &__logos {
        @media (max-width: 991px) {
            display: flex;
            justify-content: center;
        }
        img {
            height: 60px;
            display: inline-block;
            &:first-of-type {
                margin-right: 30px;
            }
        }
    }
    &__stats {
        .statistics-slide {
            cursor: grab;
        }
        @media (max-width: 767px) {
            padding-top: 30px;
            .statistics-slide {
                display: flex !important;
                flex-direction: column;
                align-items: center;
            }
        }
        p {
            color: var(--text-dark);
            @apply font-sans font-light;
            margin-bottom: 0;
            @media (max-width: 767px) {
                text-align: center;
            }
            &:nth-of-type(1) {
                @apply font-sans font-bold;
                text-transform: uppercase;
                color: var(--primary);
                display: block;
                font-size: 1.2rem;
                line-height: 2.4rem;
            }
            &:nth-of-type(2) {
                font-size: 6rem;
                line-height: 6rem;
            }
            &:nth-of-type(3) {
                font-size: 3rem;
                line-height: 4rem;
                margin-bottom: 30px;
            }
        }

        .button {
            margin: 0 auto 6rem;
            width: 100%;

            @screen lg {
                margin: 0;
                width: 17rem;
            }
        }
    }
    &__projects-link {
        @media (min-width: 768px) {
            margin-top: -70px;
        }
    }
    // margnin between slides
    .slick-slide {
        margin: 0 15px;
    }

    /* the parent */
    .slick-list {
        margin: 0 -15px;
    }
}