.about-section {
    background: var(--light-grey);
    padding: 120px 0;
    margin-top: -90px;
    @media (max-width: 991px) {
        padding: 60px 0 30px;
    }

    &__text {
        @media (max-width: 991px) {
            padding: 20px 0 20px;
        }
        p {
            font-size: 2.4rem;
            line-height: 4rem;
            font-weight: 400;
            &:last-of-type {
                margin-bottom: 60px;
                @media (max-width: 991px) {
                    margin-bottom: 30px;
                }
            }
        }
    }

    &--team {
        .items-stretch {
            display: flex;
            flex-direction: column;
        }

        @apply mt-0 py-24;

        h3 {
            padding: 0;
            color: var(--text-dark);
            font-size: 2.5rem;

            @screen xl {
                font-size: 3rem;
            }
        }

        p {
            @apply relative;
            @apply font-sans font-bold uppercase text-primary;
            @apply mx-0 mt-0 mb-auto;
            
            @screen lg {
                @apply flex items-center;
            }
        }

        hr {
            @apply mt-4;
            @apply mb-4;
            border-top-width: 1px;
            border-color: var(--text-dark);
            border-style: solid;
        }
    }

    &--team-page {
        background: var(--white);
        // .items-stretch {
        //     display: flex;
        //     flex-direction: column;
        // }
        // padding: 60px 0;

        h2 {
            padding: 0;
            margin-bottom: 0;
        }

        p.position {
            font-weight: bold;
            color: var(--primary);
            text-transform: uppercase;
            font-family: "Proxima Nova", "Arial", sans-serif;
            font-weight: 700;
            margin: 0 0 2rem 0;
            padding-right: 20px;

        //     &::after {
        //         content: "\f178";
        //         font-family: "Font Awesome 6 Pro";
        //         float: right;
        //         position: relative;
        //         left: 20px;
        //         transition: all 0.3s;
        //     }
        }

        // hr {
        //     @apply mt-4;
        //     @apply mb-4;
        //     border-top-width: 1px;
        //     border-color: var(--text-dark);
        //     border-style: solid;
        // }
    }
}


.team-member {
    @apply flex flex-col;
    @apply mb-8 items-stretch;

    ul {
        @apply mb-0 pl-0 list-none;

        @screen xl {
            @apply flex gap-4;
        }
    }

    @screen lg {
        @apply mb-0
    }
}