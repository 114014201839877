.parent-top-content {
    padding: 120px 0;
    position: relative;
    overflow-x: hidden;
    @media (max-width: 991px) {
        padding: 60px 0 40px;
    }
    .wires {
        width: 1385px;
        position: absolute;
        bottom: 120px;
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.parent-bottom-content {
    @media (min-width: 992px) {
        padding-top: 70px;
    }
    img {
        display: block;
        margin: 120px 0 140px;
        @media (max-width: 991px) {
            padding: 60px 0;
            margin: 0;
        }
    }
}