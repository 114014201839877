.standard-content {
    padding: 120px 0;

    @supports (overflow-x: clip) {
        overflow-x: clip;
    }

    @supports not (overflow-x: clip) {
        overflow-x: hidden;
    }

    @media (max-width: 991px) {
        padding: 60px 0;
    }
    a {
        transition: all 0.3s;
        color: var(--primary);
        &:hover, &:active, &:focus {
            color: var(--secondary);
        }
    }
}

.services {
    @media (min-width: 992px) {
        padding-bottom: 60px;
    }
    &__intro {
        padding-bottom: 120px;
        @media (max-width: 991px) {
            padding-bottom: 60px;
        }
    }
}

.squiggle {
    width: 600px;

    @media (min-width: $tablet-min) {
        width: 800px;
    }

    @media (min-width: $desktop-min) {
        width: 1200px;
    }
}
