.hero {
    @apply bg-center bg-cover relative w-screen;

    height: 600px;

    .site-navigation {
        @apply absolute bottom-0 left-0 w-full;
    }
    @include respond(mobile) {
        height: calc(100vh - 78px);
    }
}