.page-links {
    &--services {
        @media (min-width: 992px) {
            padding-bottom: 0 !important;
        }
    }

    .page-link {
        display: block;
        margin-bottom: 50px;
        position: relative;

        h3 {
            transition: all 0.6s;
        }

        img {
            width: 100%;
        }

        &__title {
            background: rgba(9, 11, 28, 0.9);
            padding: 30px 60px 30px 30px;
            position: absolute;
            right: 0;
            top: 130px;
            min-width: 400px;

            &--sectors {
                background: rgba(128, 128, 128, 0.9);
            }

            @media (max-width: 1199px) {
                right: -15px;
                top: 90px;
            }

            @media (max-width: 991px) {
                top: 50px;
                padding: 20px 40px 20px 20px;
                min-width: 350px;
            }

            @media (max-width: 767px) {
                top: 130px;
            }

            @media (max-width: 575px) {
                top: 90px;
            }

            @media (max-width: 440px) {
                top: 60px;
            }

            @media (max-width: 370px) {
                min-width: 100%;
                padding: 20px;
            }

            @media (max-width: 340px) {
                position: static;
            }

            span {
                color: var(--white);
                text-transform: uppercase;
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &::after {
                    content: '\f178';
                    font-family: 'Font Awesome 6 Pro';
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    position: relative;
                    left: 0;
                    transition: all 0.3s;
                }
            }
        }

        &:hover,
        &:focus,
        &:active {

            @media(min-width: 1200px) {
                .zoom-effect-container>.card-image>img {
                    -webkit-transform: scale(1.08);
                    transform: scale(1.08);
                }

                .page-link__title>span::after {
                    left: 10px;
                }
            }
        }
    }
}