:root {
    --black: #000;
    --white: #fff;
    --dark: #090b1c;
    --text-dark: #090b1c;
    --text-grey: #676767;
    --light-grey: #e2e2e2;
    --light-bg: #f0f0f0;
    --primary: #2e9bd6;
    --secondary: #92c023;
    --secondary-dark: #109034;
    --tertiary: #808080;
    --success: #38a169;
    --success-dark: #27724a;
    --danger: #e53e3e;
    --danger-dark: #bd3535;
    font-size: 10px;
}

body {
    border-color: darken(#808080, 10%);
}

/* FONTS */
// Use the below as reference for adding custom fonts to ProtoCMS

@font-face {
    font-family:"Proxima Nova";
    font-weight: 300;
    src: url('../fonts/Proxima-Nova/Proxima-Nova-Light.otf') format('opentype');
}
@font-face {
    font-family:"Proxima Nova";
    font-weight: 400;
    src: url('../fonts/Proxima-Nova/Proxima-Nova-Regular.otf') format('opentype');
}
@font-face {
    font-family:"Proxima Nova";
    font-weight: 700;
    src: url('../fonts/Proxima-Nova/Proxima-Nova-Bold.otf') format('opentype');
}

//MIXINS

/* BREAKPOINTS */
// The below mixins should only be used when absolutely necessary, otherwise refer to: https://v2.tailwindcss.com/docs/functions-and-directives#screen

$desktop: 1199px;
$tablet: 991px;
$mobile: 767px;
$xs: 575px;

$desktop-min: 1200px;
$tablet-min: 992px;
$mobile-min: 768px;
$xs-min: 576px;

@mixin respond($breakpoint) {
  @if $breakpoint == xs {
      @media screen and (max-width: $xs) { @content };
  }
  @if $breakpoint == mobile {
      @media screen and (max-width: $mobile) { @content };
  }
  @if $breakpoint == tablet {
      @media screen and (max-width: $tablet) { @content };
  }
  @if $breakpoint == desktop {
      @media screen and (max-width: $desktop) { @content };
  }
}

@mixin respond-min($breakpoint) {
  @if $breakpoint == xs {
      @media screen and (min-width: $xs-min) { @content };
  }
  @if $breakpoint == mobile {
      @media screen and (min-width: $mobile-min) { @content };
  }
  @if $breakpoint == tablet {
      @media screen and (min-width: $tablet-min) { @content };
  }
  @if $breakpoint == desktop {
      @media screen and (min-width: $desktop-min) { @content };
  }
}