h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
li,
blockquote,
p,
a,
img,
a img,
hr,
fieldset,
form,
table,
tbody,
tr,
td,
input[type=checkbox],
input[type=radio],
textarea,
select,
button {
    margin: 0;
    padding: 0;
    text-decoration: none;
    border: 0 none;
}

strong,
b {
    font-weight: 700;
}

html,
html a {
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
}

a[href^=tel] {
    color: inherit;
    text-decoration: none;
}

button:active,
button:focus,
input:active,
input:focus,
a:active,
a:focus {
    outline: none;
}

a[href^=mailto] {
    @apply break-all;

    @screen lg {
        @apply break-normal;
    }
}

body,
html {
    @apply text-black;
    @apply font-sans font-normal;

    font-size: 1.6rem;
    line-height: 1.5em;
    @apply bg-white;
}

:not(.page-header) {
    position: relative;
    z-index: 1;
}

.about-content-top,
.about-content-mid,
.about-content-bottom,
.partners-content-top,
.partners-content-bottom,
.partner-content-top,
.partner-content-bottom,
.partners-section,
.partners-page,
.project-content,
.project-content-top,
.project-content-bottom,
.parent-top-content,
.parent-bottom-content,
.page-links,
.standard-content,
.contact-form-section,
.contact-details,
.locations,
.global-presence,
.partners,
.expertise,
.accreditations {
    background: var(--white);
}