.button {
  border: 1px solid var(--primary);
  border-radius: 50px;
  background: transparent;
  @apply font-sans font-bold;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--white);
  text-transform: uppercase;
  padding: 15px 25px;
  transition: all 0.3s;
  display: inline-block;

  &:hover,
  &:active,
  &:focus {
    color: var(--white);
    background: var(--primary);
  }

  &--with-mt {
    margin-top: 30px;
  }

  &--text-dark {
    color: var(--dark);

    &:hover,
    &:focus,
    &:active {
      color: var(--white);
      background: var(--primary);
    }
  }

  &--white {
    color: var(--white);
    border-color: var(--white);

    &:hover,
    &:active,
    &:focus {
      background: var(--white);
      color: var(--secondary);
    }
  }

  &--w-170 {
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
  }

  &--wires {
    position: relative;

    &::after {
      content: '';
      background-image: url('../../images/wires--blue.png');
      background-size: cover;
      background-repeat: no-repeat;
      width: 700px;
      height: 142px;
      position: absolute;
      left: 156px;
      top: -58px;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }

  &--primary-bg-hover {

    &:hover,
    &:focus,
    &:active {
      color: var(--white);
      background: var(--primary);
    }
  }
}