#cookies-bar, #cookies-bar-tab {
    @apply fixed bottom-0 left-0;
    @apply border-primary;
    background: var(--dark);
    color: var(--white);
}

#cookies-bar-tab {
    @apply p-4 border-t border-r;
    @apply cursor-pointer text-center;
    
    z-index: 1;
}

#cookies-bar {
    @apply w-full py-8 border-t z-10;
    h5, p, p:last-of-type {
        margin-bottom: 15px;
    }
}

#cookies-bar--close--accept, #cookies-bar--close--reject {
    border-radius: 10px;
    transition: all 0.3s;
}

#cookies-bar--close--accept {
    background: var(--success);
    &:hover, &:focus, &:active {
        background: var(--success-dark)
    }
}

#cookies-bar--close--reject {
    background: var(--danger);
    &:hover, &:focus, &:active {
        background: var(--danger-dark)
    }
}