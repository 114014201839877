#enquiry_form {
    label {
        @apply font-sans font-light;
        font-size: 1.4rem;
        line-height: 2.4rem;
        display: block;
        margin-bottom: 10px;
        color: var(--text-grey);
    }

    input,
    textarea {
        @apply font-sans font-light;
        font-size: 1.4rem;
        line-height: 2.4rem;
        border: 1px solid #bcbcbc;
        background: var(--light-bg);
        border-radius: 2px;
        color: var(--dark);
        padding: 15px;
        width: 100%;
    }

    input {
        margin-bottom: 20px;
    }

    textarea {
        min-height: 150px;
        margin-bottom: 25px;

        &:focus {
            outline: none;
        }
    }

    .submit-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 575px) {
            flex-direction: column;
            align-items: flex-start;
        }

        p {
            margin-right: 30px;

            @media (max-width: 575px) {
                margin-bottom: 20px;
            }
        }
    }
}

.error,
.success {
    padding: 15px;
    color: var(--white);
    border-radius: 2px;
    margin-bottom: 20px !important;
}

.error {
    background: var(--danger);
}

.success {
    background: var(--secondary);
}

.help-block {
    ul {
        list-style: none;
        margin-bottom: 0;

        li {
            margin-bottom: 20px;
            color: var(--danger);
        }
    }

    .list-unstyled {
        padding-left: 0;
    }
}