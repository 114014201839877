@tailwind base;

@tailwind components;

@import 'settings';
@import 'generic/body';
@import 'elements/text';
@import 'elements/forms';
@import 'elements/buttons';
@import 'objects/consent';
@import 'objects/error';
@import 'components/hamburger';
@import 'components/hero';
@import 'components/nav';
@import 'components/footer';
@import 'components/slider';
@import 'components/card';
@import 'components/contact-banner';
@import 'components/quote-banner';
@import 'components/accreditations-banner';
@import 'components/page-header';
@import 'components/page-links';
@import 'components/pagination';
@import 'components/accordion';
@import 'components/about-section';
@import 'pages/home';
@import 'pages/parent';
@import 'pages/standard';
@import 'pages/contact';
@import 'pages/projects';
@import 'pages/partners';
@import 'pages/about';
//@import 'sass/diagnostics.scss';

@tailwind utilities;

.fix-absolute-position iframe {
    position: absolute!important;
}

img[alt=""], img:not([alt]) {
    border: 5px dashed #c00;
}
