.contact-banner {
    background: linear-gradient(180deg, var(--secondary) 0%, var(--secondary-dark) 100%);
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (max-width: 991px) {
        display: none;
    }

    h2 {
        color: var(--white);
    }

    h2,
    p:last-of-type {
        margin-bottom: 0;
    }

    &__wires {
        position: absolute;
        left: -490px;
        top: 50%;
        transform: translateY(-50%);
        width: 44vw;

        @media (max-width: 1200px) {
            display: none;
        }
    }
}