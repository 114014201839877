h1 {
    @apply font-sans font-light;
    font-size: 6rem;
    line-height: 6rem;
    color: var(--white);
    margin-bottom: 20px;
    @media (max-width: 991px) {
        font-size: 4rem;
        line-height: 4rem;
    }
}

h2 {
    @apply font-sans font-light; 
    font-size: 5rem;
    line-height: 5rem;
    color: var(--text-dark);
    margin-bottom: 20px;
    @media (max-width: 991px) {
        font-size: 4rem;
        line-height: 4rem;
    }
}

h3 {
    @apply font-sans font-light;
    font-size: 3rem;
    line-height: 4rem;
    color: var(--white);
    margin-bottom: 20px;
}

h4 {
    @apply font-sans;
    font-size: 2.4rem;
    line-height: 4rem;
    color: var(--text-grey);
    margin-bottom: 20px;
}

h5 {
    @apply font-sans font-bold;
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--primary)
}

// h6 available

p {
    @apply font-sans font-normal;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--text-grey);
    margin-bottom: 30px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

span {
    font-size: 1.4rem;
    line-height: 2.4rem;
}


a {
    @apply font-sans;
    @apply no-underline;
    &:hover {
        @apply no-underline;
    }
}

ul {
    list-style: disc;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: var(--text-grey);
    margin-bottom: 60px;
    padding-left: 15px;
}


ol {

}