@mixin testimonialBannerStyles() {
    img {
        width: 140px;
        display: block;
        margin-bottom: 30px;
    }
    figure {
        p {
            text-align: center;
            display: inline-block;
        }
    }
    blockquote {
        @apply text-center;

        p {
            @apply font-sans font-light;
            font-size: 3rem;
            line-height: 3rem;
            color: var(--text-dark);
            margin-bottom: 20px;
            max-width: 770px;
            @media (max-width: 575px) {
                padding: 0 10px;
            }
        }
    }
    figcaption {
        text-align: center;
        p {
            font-style: normal;
        }
    }
    .testimonial-slider {
        .testimonial-slide {
            display: flex !important;
        }
    }
}



.quote-banner {
    background: var(--light-grey);
    padding: 90px 0;
    @media (max-width: 991px) {
        padding: 60px 0;
    }
    @include testimonialBannerStyles();
}