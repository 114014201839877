// projects page

.project-content {
    padding: 120px 0 180px;
    @media (max-width: 991px) {
        padding: 60px 0 120px;
    }
}

.projects {
    background: var(--light-grey);
    &__inner {
        position: relative;
        top: -60px;
    }
}

.thumbnail {
    display: block;
    border-bottom: 1px solid var(--dark);
    margin-bottom: 60px;
    position: relative;
    &__text {
        padding: 30px 30px 70px 30px;
        display: flex;
        flex-direction: column;
        h3 {
            color: var(--dark);
            transition: all 0.3s;
            margin-bottom: 0;
        }
        p {
            margin-top: 20px;
        }
        span {
            position: absolute;
            bottom: 30px;
            left: 30px;
            @include discoverMoreBtnStyles();
            color: var(--text-dark);
            width: calc(100% - 60px);
        }
    }
    &:hover, &:active, &:focus {
        h3 {
            color: var(--primary);
        }
        .thumbnail__text span::after {
            left: 10px;
        }
        @media(min-width: 1200px) {
            .zoom-effect-container > .card-image > img {
                -webkit-transform: scale(1.08);
                transform: scale(1.08);
            }
        }
    }
}

.locations {
    padding: 120px 0;
    @media (max-width: 991px) {
        padding: 60px 0;
    }
    p:last-of-type {
        margin-bottom: 30px;
    }
}

// project page

.project-content-top {
    padding-top: 90px;
    padding-bottom: 90px;
    @media (max-width: 991px) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    h3 {
        font-weight: 500;
        color: var(--text-grey);
    }

    h4 {
        font-weight: 500;
        color: var(--primary);
    }

    p {
        &:last-of-type {
            margin-bottom: 90px;
            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
        }
    }

    ul {
        margin-bottom: 30px;
    }

    img {
        position: relative;
        top: 30px;
        z-index: 1;
        width: 100%;
    }

    + .project-content-bottom {
        @apply pt-12;
    }
}

.project-testimonial {
    background: var(--light-grey);
    padding: 120px 0 90px;
    @media (max-width: 991px) {
        padding: 90px 0 60px;
    }
    @include testimonialBannerStyles();
    blockquote {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 770px;
        p {
            max-width: none;
            margin-bottom: 35px;
            font-size: 2rem;
            &:last-of-type {
                margin-bottom: 30px;
            }
        }
    }
}

.project-content-bottom {
    padding: 120px 0;
    @media (max-width: 991px) {
        padding: 60px 0;
    }
    h3 {
        color: var(--text-grey);
    }
}
