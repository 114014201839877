.pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li {
        color: var(--text-dark);
        @apply font-sans font-bold;
        font-size: 1.4rem;
        line-height: 2.4rem;
        margin-right: 20px;
        &:last-of-type {
            margin-right: 0;
        }
    }
    .disabled, .active {
        color: var(--text-grey);
    }
}