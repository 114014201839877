.accordion-button {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #bcbcbc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
        margin: 10px 0;
        font-weight: 300;
    }
    &::after {
        content: '\f107';
        font-family: 'Font Awesome 6 Pro';
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: var(--text-grey);
        margin-right: 5px;
    }
    &:focus {
        outline: 2px solid rgba(51, 100, 44, 0.1);
    }
}
.accordion-content {
    display: none;
    padding: 10px 0;
    border-bottom: 1px solid #bcbcbc;
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }
}
.accordion-button::after {
    transition: all 0.2s;
}
.accordion-button[aria-expanded="true"]::after {
    transform: rotate(180deg);
}